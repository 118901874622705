import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './styles/home.module.css';

const Home = () => {
  useEffect(() => {
    // Dynamically adding the Bokun script to the page
    const script = document.createElement('script');
    script.src = "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=b5e820a4-d9bd-4135-a01c-bc3a9bf05060";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup by removing the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.backgroundImage}></div>
      
      {/* Title for the Categories Section */}
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Categories</h2>
      </div>
      
      <div className={styles.content}>
        <Link to="/categories/food" className={`${styles.box} ${styles.food}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Food</div>
        </Link>
        <Link to="/categories/places" className={`${styles.box} ${styles.places}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Places</div>
        </Link>
        <Link to="/categories/history" className={`${styles.box} ${styles.history}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>History</div>
        </Link>
        <Link to="/categories/culture" className={`${styles.box} ${styles.culture}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Culture</div>
        </Link>
        <Link to="/categories/nature" className={`${styles.box} ${styles.nature}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Nature</div>
        </Link>
        <Link to="/categories/activities" className={`${styles.box} ${styles.activities}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Activities</div>
        </Link>
        <Link to="/categories/events" className={`${styles.box} ${styles.events}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Events</div>
        </Link>
        <Link to="/categories/future" className={`${styles.box} ${styles.future}`}>
          <div className={styles.background}></div>
          <div className={styles.text}>Future</div>
        </Link>
      </div>

      {/* Tour Box for Pamukkale & Hierapolis Day Tour */}
      <div className={styles.tourBox}>
        <div className={styles.tourText}>
          Izmir Pamukkale & Hierapolis Day Tour With Lunch
        </div>
        {/* Bokun Widget Button for Pamukkale Tour */}
        <button
          className="bokunButton"
          id="bokun_fbd615d3_10aa_4cdf_9022_0495dbf1b377"
          data-src="https://widgets.bokun.io/online-sales/b5e820a4-d9bd-4135-a01c-bc3a9bf05060/experience/908737?partialView=1"
          data-testid="widget-book-button"
        >
          Book now
        </button>
        <style>
          {`
            #bokun_fbd615d3_10aa_4cdf_9022_0495dbf1b377 {
              display: inline-block;
              padding: 10px 20px;
              background: #408C3D;
              border-radius: 5px;
              box-shadow: none;
              font-weight: 600;
              font-size: 16px;
              text-decoration: none;
              text-align: center;
              color: #FFFFFF;
              border: none;
              cursor: pointer;
              transition: background .2s ease;
            }
            #bokun_fbd615d3_10aa_4cdf_9022_0495dbf1b377:hover {
              background: #285726;
            }
            #bokun_fbd615d3_10aa_4cdf_9022_0495dbf1b377:active {
              background: #30682e;
            }
          `}
        </style>
      </div>

      {/* Tour Box for Ephesus & Pamukkale Tour */}
      <div className={styles.tourBox}>
        <div className={styles.tourText}>
          Izmir Ephesus & Pamukkale
        </div>
        {/* Bokun Widget Button for Ephesus Tour */}
        <button
          className="bokunButton"
          id="bokun_b5bf24fc_8da4_4b5e_8aa4_ca717b669024"
          data-src="https://widgets.bokun.io/online-sales/b5e820a4-d9bd-4135-a01c-bc3a9bf05060/experience/908729?partialView=1"
          data-testid="widget-book-button"
        >
          Book now
        </button>
        <style>
          {`
            #bokun_b5bf24fc_8da4_4b5e_8aa4_ca717b669024 {
              display: inline-block;
              padding: 10px 20px;
              background: #408C3D;
              border-radius: 5px;
              box-shadow: none;
              font-weight: 600;
              font-size: 16px;
              text-decoration: none;
              text-align: center;
              color: #FFFFFF;
              border: none;
              cursor: pointer;
              transition: background .2s ease;
            }
            #bokun_b5bf24fc_8da4_4b5e_8aa4_ca717b669024:hover {
              background: #285726;
            }
            #bokun_b5bf24fc_8da4_4b5e_8aa4_ca717b669024:active {
              background: #30682e;
            }
          `}
        </style>
      </div>

      {/* Tour Box for Priene Miletos Didyma Tour */}
      <div className={styles.tourBox}>
        <div className={styles.tourText}>
          Priene Miletos Didyma From Izmir
        </div>
        {/* Bokun Widget Button for Priene Miletos Didyma Tour */}
        <button
          className="bokunButton"
          id="bokun_ae297979_7314_428d_859b_dc4fd551c5ae"
          data-src="https://widgets.bokun.io/online-sales/b5e820a4-d9bd-4135-a01c-bc3a9bf05060/experience/908700?partialView=1"
          data-testid="widget-book-button"
        >
          Book now
        </button>
        <style>
          {`
            #bokun_ae297979_7314_428d_859b_dc4fd551c5ae {
              display: inline-block;
              padding: 10px 20px;
              background: #408C3D;
              border-radius: 5px;
              box-shadow: none;
              font-weight: 600;
              font-size: 16px;
              text-decoration: none;
              text-align: center;
              color: #FFFFFF;
              border: none;
              cursor: pointer;
              transition: background .2s ease;
            }
            #bokun_ae297979_7314_428d_859b_dc4fd551c5ae:hover {
              background: #285726;
            }
            #bokun_ae297979_7314_428d_859b_dc4fd551c5ae:active {
              background: #30682e;
            }
          `}
        </style>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
